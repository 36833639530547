<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        Product Code test: <h4 v-text="productCodeValue" />

        <b-form-group>
          <label>{{ $t('level') }}</label>
          <v-select
            v-model="formData.level_id"
            :options="levelOptions"
            class="w-100"
            :reduce="val => val.id"
            label="name"
          />
        </b-form-group>

        <div v-if="formData.level_id !== ''">
          <b-form-group>
            <label>{{ $t('rotation') }}</label>
            <b-form-group>
              <b-form-radio-group
                id="btn-radios-rotation"
                v-model="formData.rotation_reference"
                button-variant="outline-primary"
                :options="rotationOptionsRadio"
                buttons
                name="radios-btn-default"
              />
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <label>Class Per Week</label>
            <b-form-group>
              <b-form-radio-group
                id="btn-radios-class-per-week"
                v-model="productCode.perWeekCount"
                button-variant="outline-primary"
                :options="[1, 2, 3, 4, 5]"
                buttons
                name="radios-btn-default"
              />
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <label>Pattern</label>
            <b-form-group>
              <b-form-checkbox-group
                v-model="productCode.pattern"
                :options="weekOptions"
                buttons
                button-variant="outline-primary"
              />
            </b-form-group>
          </b-form-group>
        </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->

    <div
      v-show="productCode.pattern.length > 0 && productCode.pattern.length === productCode.perWeekCount"
      class="row"
    >
      <div class="col-md-8">
        <label>Assign Content below:</label>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="(item, key) in tableNames"
                :key="key"
                v-text="`${item.fullName} (${item.tableName})`"
              />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(week, key1) in selectedContents"
              :key="key1"
            >
              <td
                v-for="(day, key2) in week"
                :key="key2"
              >
                <b-form-input
                  v-if="selectedContents[key1][key2]._inUse"
                  v-model="selectedContents[key1][key2].content_name"
                  readonly
                  :class="activeCalendarItem !== null && (activeCalendarItem.week === key1 && activeCalendarItem.day === key2) ? 'selected-list' : ''"
                  @click="highlightContent(key1, key2)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <button
          class="btn btn-success"
          @click.prevent="submit"
        >
          Update
        </button>
      </div>
      <!-- /.col -->

      <div class="col-md-4">
        <book-content-selection
          :selected-company="selectedCompany"
          :level-code="levelCode"
          :active-calendar-item="activeCalendarItem"
          :selected-book-codes="selectedBookCodes"
          @textBookSelected="selectProductTextbook"
        />
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  fillCalendarSelection, getTableNames, getWeekOptions, isFilledContentCanBeSubmitted,
} from '@/libs/mk/product/week'
import BookContentSelection from '@/views/Shared/DataCenter/BookContent/Selection.vue'
import { generateProductCode } from '@/libs/mk/product/productCode'
import { getRotationOptions } from '@/libs/mk/rotation/options'
import { generateSelectedBookCodes } from '@/libs/mk/product/bookCodes'
import { findActiveLevelById } from '@/libs/mk/level'
import { parseContentListForSubmission } from '@/libs/mk/product/content'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Product from '@/models/Product'
import { EventBus } from '@/../eventbus'

export default {
  name: 'SharedProductEditForm',
  components: {
    BookContentSelection,
    BFormGroup,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormInput,
    vSelect,
  },
  props: {
    levelOptions: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      productCode: {
        perWeekCount: 1,
        pattern: [],
      }, // Auto Generated product code
      selectedContents: [], // the content list, to be parsed to formData.contentList upon submit
      formData: {
        id: '',
        level_id: '',
        rotation_reference: '1',
        lang: 'en',
        contentList: [],
      },
      selectedCompany: 'mt',
      activeCalendarItem: null, // The selected input text on calendar table
      selectedBookCodes: [], // Reference for book codes for highlighting
      isDataLoading: false,
    }
  },
  computed: {
    levelCode() {
      if (this.formData.level_id !== '') {
        return findActiveLevelById(this.levelOptions, this.formData.level_id).code.toUpperCase()
      }
      return '--'
    },
    weekOptions() {
      return getWeekOptions(this.productCode.pattern, this.productCode.perWeekCount)
    },
    tableNames() {
      return getTableNames(this.levelCode)
    },
    productCodeValue() {
      // console.log('productCode', this.productCode.pattern)
      return generateProductCode(this.productCode.pattern, this.levelCode)
    },
    rotationOptionsRadio() {
      return getRotationOptions(this.levelOptions, this.formData.level_id)
    },
  },
  watch: {
    'productCode.pattern': function (pattern) {
      if (!this.isDataLoading) {
        this.selectedBookCodes = []
        this.selectedContents = fillCalendarSelection(pattern, this.levelCode)
      }
    },
    'productCode.perWeekCount': function () {
      if (!this.isDataLoading) {
        this.productCode.pattern = []
        this.selectedBookCodes = []
        this.activeCalendarItem = null
      }
    },
    'formData.level_id': function () {
      if (!this.isDataLoading) {
        this.formData.rotation_reference = 1
        this.productCode.pattern = []
        this.selectedBookCodes = []
        this.activeCalendarItem = null
      }
    },
  },
  mounted() {
    EventBus.$on('initEditProduct', this.initEditProduct)
  },
  methods: {
    async initEditProduct(id) {
      this.isDataLoading = true

      this.resetFormData()

      try {
        const response = await Product.view(id)

        this.activeCalendarItem = response.data.activeCalendarItem
        this.formData = response.data.formData
        this.productCode = response.data.productCode
        this.selectedBookCodes = response.data.selectedBookCodes
        this.selectedContents = response.data.selectedContents
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      // TODO: Check if there's a better way.
      setTimeout(() => {
        this.isDataLoading = false
      }, 500)
    },
    highlightContent(key1, key2) {
      this.activeCalendarItem = {
        week: key1,
        day: key2,
      }
    },
    selectProductTextbook(data) {
      // This will trigger only on event from data center book content selection.
      this.selectedContents[this.activeCalendarItem.week][this.activeCalendarItem.day].content_id = data.contentId
      this.selectedContents[this.activeCalendarItem.week][this.activeCalendarItem.day].content_name = data.contentCode
      this.selectedContents[this.activeCalendarItem.week][this.activeCalendarItem.day].content_title = data.contentTitle

      // For Reference
      this.selectedBookCodes = generateSelectedBookCodes(this.selectedContents)
    },
    resetFormData() {
      this.productCode = {
        perWeekCount: 1,
        pattern: [],
      }
      this.selectedContents = []

      this.formData = {
        level_id: '',
        rotation_reference: '1',
        lang: 'en',
        contentList: [],
      }

      this.activeCalendarItem = null
      this.selectedBookCodes = []
    },
    async submit() {
      const contentList = parseContentListForSubmission(this.selectedContents)

      if (isFilledContentCanBeSubmitted(contentList.length, this.productCode.perWeekCount)) {
        this.$swal({
          title: 'Error',
          text: 'Complete filling up the contents first',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.formData.productCode = this.productCodeValue
        this.formData.pattern = this.productCode.pattern.join(',')
        this.formData.classPerWeek = this.productCode.perWeekCount
        this.formData.contentList = contentList

        /* eslint no-console: 0 */

        try {
          const response = await Product.update(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.resetFormData()
          this.$emit('success')
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.selected-list {
  border-style: dashed;
  border-color: red !important;
}
</style>

<template>
  <div>
    <!-- Filters -->
    <shared-product-filters
      :level-options="levelOptions"
      :level-filter.sync="filter.level"
      :code-filter.sync="filter.code"
      :class-per-week-options="classPerWeekOptions"
      :class-per-week-filter.sync="filter.class_per_week"
      :rotation-options="rotationOptions"
      :rotation-filter.sync="filter.rotation"
      :filter="filter"
      @click="loadData"
    />

    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="showRegisterModal"
            >
              {{ $t('register') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          show-empty
          :items="items"
          :fields="fields"
          class="mb-0"
        >

          <!-- Column: id -->
          <template #cell(id)="data">
            <span
              class="align-middle ml-50"
              v-text="data.item.count"
            />
          </template>

          <!-- Column: Level -->
          <template #cell(level)="data">
            {{ data['item']['level']['name'] }}
          </template>

          <!-- Column: Class Per Week -->
          <template #cell(code)="data">
            {{ data['item']['code'] }}
          </template>

          <!-- Column: Class Per Week -->
          <template #cell(class_per_week)="data">
            {{ data['item']['class_per_week'] }}
          </template>

          <!-- Column: Rotation -->
          <template #cell(rotation)="data">
            {{ data['item']['rotation_reference'] }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="editProduct(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteProduct(data)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

    <b-modal
      ref="register-product-modal"
      centered
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Register Form"
    >
      <ProductRegisterForm
        :level-options="levelOptions"
        @success="onStoreSuccess"
      />
    </b-modal>

    <b-modal
      ref="edit-product-modal"
      centered
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Edit Form"
      static
    >
      <ProductEditForm
        :level-options="levelOptions"
        @success="onUpdateSuccess"
      />
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { EventBus } from '@/../eventbus'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductRegisterForm from '@/views/Shared/Product/RegisterForm.vue'
import ProductEditForm from '@/views/Shared/Product/EditForm.vue'
import Product from '@/models/Product'
import SharedProductFilters from '../Shared/Product/Filter.vue'

export default {
  name: 'AdminProduct',
  components: {
    ProductRegisterForm,
    ProductEditForm,
    SharedProductFilters,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      required,
      fields: [
        'id',
        'level',
        'code',
        'class_per_week',
        'rotation',
        'actions',
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        level: typeof this.$route.query.level !== 'undefined' ? parseInt(this.$route.query.level, 0) : null,
        code: typeof this.$route.query.code !== 'undefined' ? this.$route.query.code : '',
        class_per_week: typeof this.$route.query.class_per_week !== 'undefined' ? this.$route.query.class_per_week : '',
        rotation: typeof this.$route.query.rotation !== 'undefined' ? this.$route.query.rotation : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      levelOptions: [],
      classPerWeekOptions: [
        { label: 'All', value: '' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
      rotationOptions: [
        { label: 'All', value: '' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true

      const searchParams = {}
      searchParams.page = page
      Object.keys(this.filter).forEach(e => {
        if (e === 'level') {
          if (parseInt(this.filter[e], 0) > 0) {
            searchParams[e] = parseInt(this.filter[e], 0)
          }
        } else if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })

      const response = await Product.list(searchParams)
      this.items = response.data.data
      this.levelOptions = response.data.meta.options.levels
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.loading = false
    },
    async deleteProduct(data) {
      const doWeDelete = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (doWeDelete.value) {
        try {
          const response = await Product.destroy({ id: data.item.id })
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    showRegisterModal() {
      this.$refs['register-product-modal'].show()
    },
    onStoreSuccess() {
      this.$refs['register-product-modal'].hide()
      this.loadData()
    },
    editProduct(data) {
      this.$refs['edit-product-modal'].show()
      EventBus.$emit('initEditProduct', data.item.id)
    },
    onUpdateSuccess() {
      this.$refs['edit-product-modal'].hide()
      this.loadData()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.selected-list {
  border-style: dashed;
  border-color: red !important;
}
</style>

<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('level') }}</label>
          <v-select
            :model="filter.level"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="levelOptions"
            class="w-100"
            :reduce="val => val.id"
            label="name"
            :value="levelFilter"
            @input="(val) => $emit('update:levelFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('code') }}</label>
          <b-form-input
            :value="codeFilter"
            @input="(val) => $emit('update:codeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('class_per_week') }}</label>
          <v-select
            :model="filter.class_per_week"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="classPerWeekOptions"
            class="w-100"
            :value="classPerWeekFilter"
            :reduce="val => val.value"
            @input="(val) => $emit('update:classPerWeekFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('rotation') }}</label>
          <v-select
            :model="filter.rotation"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="rotationOptions"
            class="w-100"
            :value="rotationFilter"
            :reduce="val => val.value"
            @input="(val) => $emit('update:rotationFilter', val)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-1 text-right"
        >
          <b-button
            type="submit"
            variant="outline-primary"
            @click="$emit('click')"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('search') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
  },
  props: {
    levelOptions: {
      type: Array,
      required: true,
    },
    classPerWeekOptions: {
      type: Array,
      required: true,
    },
    rotationOptions: {
      type: Array,
      required: true,
    },
    codeFilter: {
      type: [String, null],
      default: null,
    },
    levelFilter: {
      type: [Number, null],
      default: null,
    },
    classPerWeekFilter: {
      type: [String, null],
      default: null,
    },
    rotationFilter: {
      type: [String, null],
      default: null,
    },
    filter: {
      type: [Array, Object],
      required: false,
      default: () => ({
        rotation: '',
        subject: '',
        level: '',
      }),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

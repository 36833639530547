import axios from '@/libs/axios'

class Product {
  static list(params) {
    return axios.post('admin/product/list', params)
  }

  static store(params) {
    return axios.post('admin/product/store', params)
  }

  static view(id) {
    return axios.post(`admin/product/view/${id}`)
  }

  static update(params) {
    return axios.post('admin/product/update', params)
  }

  static destroy(id) {
    return axios.post('admin/product/delete', id)
  }
}

export default Product
